import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Header} from "antd/es/layout/layout";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {cdnApiService} from "../../services/cdn/CdnApiService";
import './UpdateIdCardOffer.css';
import {RouteComponentProps} from "react-router";
import {Row, Divider, Layout, Col, Drawer, Typography, Collapse, Modal, Result} from "antd";
import {UpdateIdCardOfferStates} from "./types/UpdateIdCardOfferTypes";
import {CaretRightOutlined} from "@ant-design/icons";
import Crop from "../../components/image/Crop";
import Spin from "antd/lib/spin";
import * as url from "url";

const {Text} = Typography;
const {Panel} = Collapse;

export interface AffiliateLandingProps {
}

class UpdateIdCardOffer extends Component<AffiliateLandingProps & RouteComponentProps, UpdateIdCardOfferStates> {
    private readonly initState!: UpdateIdCardOfferStates;

    constructor(props: AffiliateLandingProps & RouteComponentProps | any) {
        super(props);
        const {match} = this.props;
        const offerCode = match?.url?.replace("/update-id-card-owner/", "");
        const urlParts = url.parse(window.location.href, true);

        this.initState = {
            processing: false,
            visibleFilter: false,
            offerCode: offerCode,
            offerOwner: "",
            token: urlParts?.query?.token,
            validateToken: false,
            validateProcessing: true,
            imageUrl: "",
            page: 0,
        }
        this.state = {...this.initState}
    }

    showFilterDrawer = () => {
        this.setState({
            visibleFilter: true,
        })
    }

    closeFilterDrawer = () => {
        this.setState({
            visibleFilter: false,
        })
    }

    resizeImage = async (base64Str: any, imgWidth: number, imgHeight: number) => {
        let img = new Image();
        img.src = base64Str;
        let canvas = document.createElement('canvas');
        let MAX_WIDTH = imgWidth;
        let MAX_HEIGHT = imgHeight;
        let width = img.width;
        let height = img.height;

        if (width > height) {
            if (width > MAX_WIDTH) {
                height *= MAX_WIDTH / width;
                width = MAX_WIDTH;
            }
        } else {
            if (height > MAX_HEIGHT) {
                width *= MAX_HEIGHT / height;
                height = MAX_HEIGHT;
            }
        }
        canvas.width = width;
        canvas.height = height;
        let ctx: any = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        return canvas.toDataURL("image/jpeg", 0.7);
    }

    onSaveImage = async (base64DataImagePng: any) => {
        if (!base64DataImagePng || base64DataImagePng === "#") {
            Modal.error({
                title: 'Process Error',
                content: 'silakan lakukan crop image terlebih dahulu',
            });
            return;
        }

        this.setState({
            processing: true,
        });

        const newImage = await this.resizeImage(base64DataImagePng, 900, 900);
        // const newThumbnail = await this.resizeImage(base64DataImagePng, 180, 180);

        try {
            await cdnApiService.uploadIdCardBase64({
                id_image: this.state.offerCode,
                image_data: newImage,
            }).then(async successData => {
                await this.setState({
                    imageUrl: successData?.data?.data?.url_document,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                processing: false
            });
        }

        await new Promise(resolve => setTimeout(resolve, 200));

        const dataUpdate = {
            update_type: "validate-update-id-card-owner",
            company: "amarta",
            admin_id: "customer",
            admin_name: "customer",
            offer_code: this.state.offerCode,
            token: this.state.token,
            image_url: this.state.imageUrl,
        }

        try {
            await offerApiServices.updateOffer(dataUpdate)
                .then(successData => {
                    this.setState({
                        page: 2,
                        processing: false,
                    });
                });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                processing: false,
            });
        }
    }

    async componentDidMount() {
        if (!this.state.offerCode || !this.state.token) {
            this.setState({
                validateProcessing: false,
                validateToken: false,
            });
        } else {
            offerApiServices.getDetailOffer({
                    offerCode: this.state.offerCode,
                    token: this.state.token
                }
            ).then(dataFetchPromo => {
                this.setState({
                    validateProcessing: false,
                    validateToken: true,
                    offerOwner: dataFetchPromo?.data?.meta?.id_card_owner?.full_name?.toUpperCase(),
                    page: 1
                });
            }).catch(e => {
                this.setState({
                    validateProcessing: false,
                    validateToken: false,
                });
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <Layout className="layout">
                    <div style={(this.state.validateProcessing) ? {textAlign: "center", padding: 20, paddingTop: 100} : {display: 'none'}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Loading..."/>
                    </div>
                    <div style={(!this.state.validateProcessing && !this.state.validateToken) ? {textAlign: "center"} : {display: 'none'}}>
                        <Col style={{}} xl={{span: 8, offset: 8}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                            <img style={{paddingTop: 50, paddingBottom: 10}} src={'../assets/img/dev.png'} alt={`amartahonda-logo`}/>
                            <h3>
                                Kode Offer Dan Token tidak valid.<br/>Pastikan kode yang terdapat pada alamat url sudah benar.
                            </h3>
                        </Col>
                    </div>
                    <div style={(this.state.page === 1) ? {} : {display: 'none'}}>
                        <Col xl={{span: 8, offset: 8}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}}>
                            <Header className={`desktop-header-search`} style={{
                                backgroundColor: '#fff',
                                paddingBottom: 55,
                                paddingTop: 7,
                                borderBottom: "#f0f2f5 3px solid"
                            }}>
                                <Row>
                                    <Col span={21}>
                                        <img className={`header-logo`} src={'../assets/img/favicon.png'} alt={`amartahonda-logo`}/>
                                        <b className={"headerTitle"}>Amartahonda</b>
                                    </Col>
                                    <Col span={3}>
                                        {/*<MenuOutlined onClick={this.showFilterDrawer} className={`header-control`}/>*/}
                                    </Col>
                                </Row>
                            </Header>
                            <div style={{backgroundColor: '#fff', paddingTop: '2%'}}>
                                <Drawer title="Menu" placement="right" onClose={this.closeFilterDrawer} getContainer={false} visible={this.state.visibleFilter} style={this.state.visibleFilter ? {position: 'absolute'} : {display: "none"}}>
                                    <div>
                                    <span style={{cursor: "pointer"}}>
                                        <Text type="secondary">Home</Text>
                                        <Text type="secondary" style={{float: "right"}}></Text>
                                        <Divider style={{marginTop: 14}}/>
                                    </span>
                                    </div>
                                </Drawer>

                                <div style={{paddingLeft: "3%", paddingRight: "3%"}}>
                                    <Divider orientation="left">Unggah KTP Pemilik</Divider>
                                    <Typography.Text type="secondary" style={{paddingLeft: 7, paddingRight: 25}}> Nomor SPK: </Typography.Text> {this.state.offerCode} <br/>
                                    <Typography.Text type="secondary" style={{paddingLeft: 7, paddingRight: 12}}> Nama Pemilik: </Typography.Text> {this.state.offerOwner}
                                    <Divider/>

                                    <Col xs={24} xl={24} className={`pad-10`}>
                                        <div className={`site-layout-background site-layout-content`}>
                                            <Crop params={this.state} onSaveImage={this.onSaveImage}/>
                                        </div>
                                    </Col>
                                </div>

                            </div>
                            <footer style={{paddingTop: 20, paddingBottom: 20, backgroundColor: "#fff"}}>
                                <div style={{marginTop: 150, marginBottom: "10", paddingTop: 20, backgroundColor: "#fafafa"}}>
                                    <h1 className={"h2"}>Pertanyaan Umum.</h1>
                                    <Collapse
                                        bordered={false}
                                        defaultActiveKey={['0']}
                                        expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0}/>}
                                        className="site-collapse-custom-collapse"
                                    >
                                        <Panel header="Kenapa saya perlu upload ktp?" key="1" className="site-collapse-custom-panel">
                                            <p className={"pDesc"}>Data ktp konsumen dibutuhkan untuk proses pembelian kendaraan, dan menjadi salah satu syarat dokumen yang dibutuhkan.</p>
                                        </Panel>
                                        <Panel header="Apakah link website ini aman?" key="2" className="site-collapse-custom-panel">
                                            <p className={"pDesc"}>Link url aman, namun mohon untuk tidak menyebarkan token yang tertaut pada link yang anda dapatkan.</p>
                                        </Panel>
                                        <Panel header="Bagaimana cara mengunggah ktp pemilik?" key="3" className="site-collapse-custom-panel">
                                            <p className={"pDesc"}>
                                                <ul>
                                                    <li>Klik Tombol "Pilih Gambar KTP"</li>
                                                    <li>Lakukan penyesuaian pada area yang akan crop</li>
                                                    <li>Klik Tombol "Crop Image" jika dirasa penyesuaian telah sesuai</li>
                                                    <li>Perhatikan bagian "Crop Result", jika dirasa sudah sesuai anda dapat menyelesaikan proses, dengan melakukan klik Tombol "Upload & Save Image"</li>
                                                </ul>
                                            </p>
                                        </Panel>
                                    </Collapse>
                                </div>
                            </footer>
                            <footer style={{textAlign: "center", paddingTop: 20, paddingBottom: 20, backgroundColor: "#fff"}}>
                                <b>Copyright © PT TRIMITRA SUKSES - 2022</b>
                            </footer>
                        </Col>
                    </div>
                    <div style={(this.state.page === 2) ? {display: "block"} : {display: 'none'}}>
                        <div style={{minHeight: '92vh', backgroundColor: '#fff', padding: '4.5%', paddingTop: '2%'}}>
                            <Result
                                status="success"
                                title="Proses Sukses"
                                subTitle="Proses Unggah Image KTP Sukses."
                            />
                        </div>
                    </div>
                </Layout>

            </React.Fragment>
        );
    }
}

export default withRouter(UpdateIdCardOffer);
