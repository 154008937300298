import React, {Component} from 'react';
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {RouteComponentProps} from "react-router";
import {Col, Divider, Row, Typography, Layout} from "antd";
import {AuditOutlined} from "@ant-design/icons";
import {LandingStates} from "./types/LandingTypes";
const {Text} = Typography;
const {Content} = Layout;

export interface LandingProps {
}

class Landing extends Component<LandingProps & RouteComponentProps, LandingStates> {
    private readonly initState!: LandingStates;

    constructor(props: LandingProps & RouteComponentProps | any) {
        super(props);

        this.initState = {
            processing: false,
        }
        this.state = {...this.initState}
    }

    render() {
        return (
            <React.Fragment>
                <Content style={{margin: '0px 16px -10px', padding: 0, minHeight: '100vh'}}>
                    <Row>
                        <Col xs={24} xl={7} className={`pad-7`}></Col>
                        <Col xs={24} xl={10} className={`pad-10`}>
                            <div className={`site-layout-background site-layout-content`}>
                                <div style={{textAlign: "center"}}>Amarta VIP User</div><br/><br/>
                                <Link to={`/update-id-card-owner`}>
                                    <span style={{cursor:"pointer"}}>
                                        <Text type="secondary">Update Id Card User Offer Amartahonda</Text>
                                        <Text type="secondary" style={{float: "right"}}><AuditOutlined/></Text>
                                        <Divider style={{marginTop: 14}}/>
                                    </span>
                                </Link>
                            </div>
                        </Col>
                        <Col xs={24} xl={7} className={`pad-10`}></Col>
                    </Row>
                </Content>

            </React.Fragment>
        );
    }
}

export default withRouter(Landing);
