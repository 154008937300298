/**
 * PRODUCTION LINK.
 */
const baseUrlArt = "";
const baseUrlOffer = "https://42cjbxpaa8.execute-api.ap-southeast-1.amazonaws.com/v1";
const baseUrlCdnUpload = "https://hbxhwzeej9.execute-api.ap-southeast-1.amazonaws.com/v1";

/**
 * DEVELOPMENT LINK.
 *
 *
 */
// const baseUrlArt = "";
// const baseUrlOffer = "http://localhost:9999/offer";
// const baseUrlCdnUpload = "http://localhost:9999/cdn";

/**
 * APIKEY LINK.
 */
const xApiKeyArt = '';
const xApiKeyOffer = '9bekwohY878MgiMsRL0Wk2Xsgv4QsxtW4jEIuBqb';
const xApiKeyCdnUpload = 'zXSa4iZwycS0O5kaoMvi2yAFiIpimTZ55MvvJLy0';

export {baseUrlArt, xApiKeyArt, baseUrlOffer, xApiKeyOffer, baseUrlCdnUpload, xApiKeyCdnUpload}
