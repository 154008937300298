const initialState = {
    RlistNotification: [{
        title: "-",
        message: "Tidak ada notifikasi baru",
        time: 0,
        type: 'none',
    }],
}

const reducer = (state = initialState, action: any) => {
    let currentState = {...state}
    switch (action.type) {
        case "CHANGE_LIST_NOTIFICATION":
            currentState.RlistNotification = action.value;
            break;
    }
    return currentState;
}

export default reducer;
