import axios from "axios";
import {baseUrlOffer, xApiKeyOffer} from "../../config/api/apiConfig";

const companyCode = 'amarta';

class OfferApiServices {
    private axios = axios.create({
        baseURL: baseUrlOffer,
    });

    public async getDetailOffer(params?: any) {
        try {
            return await this.axios.get<any>(`/offer/validate-offer/${companyCode}/${params.offerCode}?token=${params.token}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async updateOffer(inputData?: any) {
        try {
            return await this.axios.put<any>('/update-offer',
                inputData,{
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }
}

export const offerApiServices = new OfferApiServices();
