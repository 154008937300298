import React from 'react';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {Provider} from "react-redux";
import {store} from './redux';
import Landing from "./screen/landing/Landing";
import UpdateIdCardOffer from "./screen/updateIdCardOffer/UpdateIdCardOffer";

function App() {
    return (
        <Provider store={store}>
            <BrowserRouter>
                <Switch>
                    <Route path={"/update-id-card-owner/:offerCode"}><UpdateIdCardOffer/></Route>
                    <Route path={"/"}><Landing/></Route>
                </Switch>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
