import React, {useState} from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import "./Crop.css";
import {CloudUploadOutlined, RadiusSettingOutlined, SaveOutlined} from "@ant-design/icons";
import {Button, Col, Divider, Row} from "antd";

export const Crop: any = (props: any) => {
    const [showCropArea, setshowCropArea] = useState(true);
    const [cropStatus, setCropStatus] = useState(false);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();
    const onChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        if(files[0]){
            reader.readAsDataURL(files[0]);
        }
    };
    const [image, setImage] = useState("/assets/img/sample.jpg");

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setshowCropArea(false);
            setCropData(cropper.getCroppedCanvas().toDataURL());
            // console.log(cropData);
            setCropStatus(true);
        }
    };

    const saveImage = () => {
        props.onSaveImage(cropData);
    };

    const showCrop = () => {
        setshowCropArea(true);
    };

    // @ts-ignore
    return (
        <React.Fragment>
            <Row>
                <Col xs={24} xl={24} className={`pad-10 mb-10`}>
                    <input onChange={onChange} type="file" className="upload-box" placeholder="Upload File"/>

                    <label className="custom-file-upload" onClick={showCrop}>
                        <input disabled={props.params?.processing} type="file" multiple onChange={onChange}/>
                        <i className="fa fa-cloud-upload"/> <CloudUploadOutlined/> Pilih Gambar KTP
                    </label>

                    <span style={showCropArea ? {} : {display: 'none'}}>
                        <Button onClick={getCropData} loading={props.params?.processing} style={{background: "#69a68b", border: "#69a68b", color: "#fff", float: "right"}} type="primary" icon={<RadiusSettingOutlined/>}> Crop Image </Button>
                    </span>
                    <div style={showCropArea ? {marginTop:20} : {display: 'none'}}>
                        <Cropper
                            style={{width: "100%"}}
                            zoomTo={0.1}
                            initialAspectRatio={16 / 9}
                            aspectRatio={16 / 9}
                            preview=".img-preview"
                            src={image}
                            viewMode={1}
                            minCropBoxHeight={500}
                            minCropBoxWidth={500}
                            background={false}
                            responsive={true}
                            autoCropArea={1}
                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                            onInitialized={(instance) => {
                                setCropper(instance);
                            }}
                            guides={true}
                        />
                        <i style={{fontSize: "85%"}}>*Pajak progresif dari KTP Pemilik akan di tanggung oleh pemilik</i>
                    </div>
                </Col>

                <Divider/>

                <Col style={cropStatus ? {} : {display: 'none'}} xs={24} xl={10} className={`pad-10 mb-10`}>
                    <h3><span>Crop Result:</span></h3>
                    <div style={cropData !== "#" ? {width: "100%"} : {display: 'none'}}>
                        <img style={{width: "100%"}} src={cropData} alt="please attach file then crop"/>
                    </div>
                </Col>

                <Col style={cropStatus ? {} : {display: 'none'}} xs={24} xl={2} className={`pad-10 mb-10`}>
                </Col>

                <Col style={cropStatus ? {} : {display: 'none'}} xs={24} xl={12} className={`pad-10 mb-10`}>
                    <h3><span>Upload Image:</span></h3>
                    <Button loading={props.params?.processing} onClick={saveImage} type="primary" icon={<SaveOutlined/>} style={{background: "#69a68b", border: "#69a68b", color: "#fff"}}> Upload & Save Image </Button>
                </Col>

                <br style={{clear: "both"}}/>
            </Row>
        </React.Fragment>
    );
};

export default Crop;
